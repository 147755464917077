
    import Vue from "vue";
    import Component from "vue-class-component";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";

    import ApiButton from "@/components/ApiButton.vue";
    import ModuleCheckboxes from "@/components/ModuleCheckboxes.vue";
    import MultiSelectList from "@/components/MultiSelectList.vue";

    import store from "@/store/store";
    import router, { beforeRouteLeave } from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import eventBus from "@/stuff/EventBus";
    import dirtyness from "@/stuff/Dirtyness";

    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import { ILookupItem } from "@/model/LookupItem";
    import { Module } from "@/model/Module";
    import { Buyer as ModelBuyer } from "@/model/Buyer";
    import { User } from "@/model/User";
    import { ISendPasswordResetRequest } from "@/model/ISendPasswordResetRequest";
    import { ISendPasswordResetResponse } from "@/model/ISendPasswordResetResponse";
    import { EmailDomain } from "@/model/EmailDomain";

    @Component({
        components: { ApiButton, ModuleCheckboxes, MultiSelectList },
        beforeRouteLeave
    })
    export default class Buyer extends Vue {

        //
        // -- lifecycle hooks (https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks)
        //

        mounted() {
            store.dispatch("loadCompanyTypeList");
            store.dispatch("loadCountryList");
            store.dispatch("loadTradeList"),

            // use event bus to set own reactive property whenever global dirty flag changes
            eventBus.$on("dirtyness-changed", (isDirty: boolean) => {
                this.isDirty = isDirty;
            });

            this.loadModules();
            const buyerID = +this.$router.currentRoute.params.buyerID;
            this.load(buyerID);
        }

        //
        // -- properties
        //

        private isDirty: boolean = false;
        private readonly buyer = new ModelBuyer();
        private domain: string = "";
        private defaultDomain: string = "";

        private readonly dirtynessOptions = {
            model: this.buyer,
            isDeep: true,
            excludeList: ["regionCount", "userCount", "users", ...dirtyness.defaultAuditFieldList() ]
        };

        // computed
        private get title(): string {
            if (!this.buyer) return "- - -";
            if (utils.isEmptyId(this.buyer.id)) return "New Buyer";
            return this.buyer.description ? this.buyer.description : "(No buyer name)";
        }

        private moduleList: Array<Module> = [];

        get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        get tradeList(): Array<ILookupItem> { return this.$store.state.tradeList; }
        get tradeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.tradeList); }

        private get canDelete() {
            return !this.buyer.isNew && !utils.hasDateValue(this.buyer.deleted);
        }

        private get saveButtonText() {
            return utils.hasDateValue(this.buyer.deleted) ? "Save Buyer and Un-archive" : "Save Buyer";
        }

        //
        // -- watchers
        //

        @Watch("buyer", { deep: true })
        onBuyerChanged() {
            dirtyness.dataChanged(this.dirtynessOptions);
        }

        @Watch("defaultDomain")
        onDefaultDomainChanged(domain: string) {
            this.buyer.emailDomains.forEach(e => e.default = false);
            if (!utils.isEmptyOrWhitespace(domain))
            this.buyer.emailDomains.find(e => e.domain == domain)!.default = true;
        }

        //
        // -- methods
        //

        goToBuyers() {
            // show buyer search (we could go back - but no guarantee that we came from search)
            // TODO - maybe check history and go back?
            router.push("/buyers");
        }

        private async loadModules() {
            const params = new ModuleSearchParameters();
            const responseData = await apiClient.post("api/module/search", params);
            this.moduleList = responseData.list;
            console.log("@@@ Buyer.vue - loadModules", this.moduleList);
        }

        async load(buyerID: number) {
            this.$v.$reset();
            utils.resetObject(this.buyer);
            if(buyerID > 0) {
                //TODO - show waiting animation...
                const serverBuyerData = await apiClient.get(`api/buyer/Load?id=${buyerID}`);
                // nb - buyer.update maps buyer.users too
                this.buyer.update(serverBuyerData);
                dirtyness.setClean(this.dirtynessOptions);

                if (this.buyer.emailDomains.find(e => e.default)) {
                    this.defaultDomain = this.buyer.emailDomains.find(e => e.default)!.domain
                }

                console.log("@@@ Buyer.vue - load - server data", serverBuyerData);
                console.log("@@@ Buyer.vue - load - client data", this.buyer);
            }
        }

        async save(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.buyer.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/buyer/save", this.buyer, event);
     
            if (this.buyer.isNew) {
                this.buyer.id = response.newID;
                history.replaceState({}, "", this.$route.path.replace("/0", "/" + response.newID));
            }
            else if(this.buyer.isDeleted) {
                // saving un-deletes
                this.buyer.deleted = new Date(utils.emptyDateValue);
                this.buyer.deletedByUserID = 0;
            }
            dirtyness.setClean(this.dirtynessOptions);
            toastr.success("Saved");           
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.buyer.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.buyer.description + "'", {
                title: "Delete Buyer",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/buyer/delete", this.buyer, event);
            toastr.warning("Deleted");
            dirtyness.reset();
            this.goToBuyers();
        }

        async sendReminder(user: User) {
            const shouldSend: boolean = await this.$bvModal.msgBoxConfirm("Do you want to send a reset link to " + user.fullname, {
                title: "Password Reset",
                okVariant: "danger",
                okTitle: "Yes, send!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldSend) return;

            const request: ISendPasswordResetRequest = {
                email: user.email,
                isSelfInitiated: false 
            }
            const response = await apiClient.post("/Api/Authentication/sendPasswordReset", request) as ISendPasswordResetResponse;
            if(response.isSuccess) {
                toastr.success(user.forename, "Sent");
            }
            else {
                toastr.error(response.message, "FAILED"); // maybe use message box rather than toast?
            }           
        }

        private addDomain() {
            if (this.domain.indexOf("@") == 0) {
                this.domain = this.domain.substring(1);
            }

            if (utils.isEmptyOrWhitespace(this.domain)) {
                toastr.info("Please enter a domain");
                return;
            }

            const domain = `@${this.domain}`;

            if (this.buyer.emailDomains.filter(d => d.domain == this.domain).length > 0) {
                toastr.info("This domain has already been added");
                return;
            }

            this.buyer.emailDomains.push(new EmailDomain({ 
                domain: domain, 
                users: 0, 
                buyerID: this.buyer.id, 
                default: this.buyer.emailDomains.length == 0 
            }));
            this.domain = "";

            if (this.buyer.emailDomains.length == 1) {
                this.defaultDomain = this.buyer.emailDomains[0].domain;
            }
        }

        private removeDomain(domain: EmailDomain) {
            this.buyer.emailDomains.splice(this.buyer.emailDomains.indexOf(domain), 1);
        }

        @Validations()
        validations() {
            const validations = {
                buyer: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.buyer.description = { required };
            return validations;
        }
    }
