

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { Module, IModule } from "@/model/Module";
    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import apiClient from "@/stuff/ApiClient";

    interface IModuleSelectable extends IModule {
        isSelected: boolean;
    }

    @Component({})
    export default class ModuleCheckboxes extends Vue {

        @Prop({ required: true })
        private idList!: Array<number>;

        private modules: Array<IModuleSelectable> = [];

        mounted() {
            this.loadModules();
        }

        private onChange(x: any) {
            console.log("onChange: ", x);
        }

        private async loadModules() {
            const params = new ModuleSearchParameters();
            const responseData = await apiClient.post("api/module/search", params);
            this.modules = responseData.list;

            console.log("loadModules: ", this.modules);

            //const list = await apiClient.post("api/module/search", params);
            // list.unshift({ id: 0, description: "Choose..." });
        }
    }

